var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "인허가 정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.popupParam.limLicenseId &&
                                    !_vm.isRevision &&
                                    _vm.isSaveable,
                                  expression:
                                    "editable && popupParam.limLicenseId && !isRevision && isSaveable",
                                },
                              ],
                              attrs: { label: "개정", icon: "restart_alt" },
                              on: { btnClicked: _vm.SetRevision },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.popupParam.limLicenseId &&
                                    _vm.isRevision &&
                                    _vm.isSaveable,
                                  expression:
                                    "editable && popupParam.limLicenseId && isRevision && isSaveable",
                                },
                              ],
                              attrs: { label: "개정취소", icon: "restart_alt" },
                              on: { btnClicked: _vm.CancelRevision },
                            }),
                            _vm.editable && _vm.isSaveable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.popupParam.limLicenseId &&
                            _vm.isDeletable
                              ? _c("c-btn", {
                                  attrs: { label: "삭제", icon: "remove" },
                                  on: { btnClicked: _vm.deleteData },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "인허가번호",
                              name: "licenseNo",
                            },
                            model: {
                              value: _vm.data.licenseNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "licenseNo", $$v)
                              },
                              expression: "data.licenseNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: false,
                              label: "관련법규",
                              name: "relatedLawsName",
                            },
                            model: {
                              value: _vm.data.relatedLawsName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "relatedLawsName", $$v)
                              },
                              expression: "data.relatedLawsName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              afterIcon:
                                _vm.editable && !_vm.disabled
                                  ? [
                                      {
                                        name: "search",
                                        click: true,
                                        callbackName: "searchLicenseKindId",
                                        color: "teal",
                                      },
                                      {
                                        name: "close",
                                        click: true,
                                        callbackName: "removeLicenseKindId",
                                        color: "red",
                                      },
                                    ]
                                  : null,
                              editable: _vm.editable,
                              disabled: _vm.disabled,
                              readonly: true,
                              label: "인허가종류",
                              name: "limLicenseKindName",
                            },
                            on: {
                              searchLicenseKindId: _vm.searchLicenseKindId,
                              removeLicenseKindId: _vm.removeLicenseKindId,
                            },
                            model: {
                              value: _vm.data.limLicenseKindName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "limLicenseKindName", $$v)
                              },
                              expression: "data.limLicenseKindName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              disabled: _vm.disabled,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.editable,
                              label: "인허가시작일",
                              name: "licenseStartDate",
                            },
                            model: {
                              value: _vm.data.licenseStartDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "licenseStartDate", $$v)
                              },
                              expression: "data.licenseStartDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.editable,
                              label: "인허가종료일",
                              name: "licenseEndDate",
                            },
                            model: {
                              value: _vm.data.licenseEndDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "licenseEndDate", $$v)
                              },
                              expression: "data.licenseEndDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              editable: _vm.editable,
                              label: "관련부서",
                              name: "relatedDeptCd",
                            },
                            model: {
                              value: _vm.data.relatedDeptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "relatedDeptCd", $$v)
                              },
                              expression: "data.relatedDeptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              editable: _vm.editable,
                              data: _vm.data,
                              type: "user",
                              label: "인허가담당자",
                              name: "chargeUserId",
                            },
                            model: {
                              value: _vm.data.chargeUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "chargeUserId", $$v)
                              },
                              expression: "data.chargeUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              label: "비고",
                              name: "remark",
                            },
                            model: {
                              value: _vm.data.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "remark", $$v)
                              },
                              expression: "data.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              disabled: true,
                              codeGroupCd: "LICENSE_CREATE_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "licenseCreateTypeCd",
                              label: "인허가 등록 구분",
                            },
                            model: {
                              value: _vm.data.licenseCreateTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "licenseCreateTypeCd", $$v)
                              },
                              expression: "data.licenseCreateTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c(
                  "c-table",
                  {
                    ref: "equipmentTable",
                    attrs: {
                      title: "인허가관련 설비",
                      tableId: "equipmentTable",
                      columns: _vm.gridequipment.columns,
                      data: _vm.gridequipment.data,
                      columnSetting: false,
                      selection: "multiple",
                      rowKey: "equipmentCd",
                      filtering: false,
                      usePaging: false,
                      hideBottom: true,
                      gridHeight: "282px",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "추가", icon: "add" },
                                  on: { btnClicked: _vm.addRowEquipment },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "삭제", icon: "remove" },
                                  on: { btnClicked: _vm.removeRowEquipment },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c(
                  "c-table",
                  {
                    ref: "materialTable",
                    attrs: {
                      title: "인허가관련 화학자재",
                      tableId: "materialTable",
                      columns: _vm.gridmaterial.columns,
                      data: _vm.gridmaterial.data,
                      columnSetting: false,
                      selection: "multiple",
                      rowKey: "mdmChemMaterialId",
                      filtering: false,
                      usePaging: false,
                      hideBottom: true,
                      gridHeight: "282px",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "추가", icon: "add" },
                                  on: { btnClicked: _vm.addRowMaterial },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "삭제", icon: "remove" },
                                  on: { btnClicked: _vm.removeRowMaterial },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-upload", {
                  attrs: {
                    maxheight: "max-height:130px;min-height:130px;",
                    editable: _vm.editable,
                    label: "관련서류",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-upload", {
                  attrs: {
                    maxheight: "max-height:130px;min-height:130px;",
                    editable: _vm.editable,
                    label: "인허가파일",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _vm.popupParam.limLicenseId
                  ? _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "개정", topClass: "topcolor-orange" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "개정번호",
                                        name: "revisionNum",
                                      },
                                      model: {
                                        value: _vm.data.revisionNum,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "revisionNum", $$v)
                                        },
                                        expression: "data.revisionNum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "개정일시",
                                        name: "regDtStr",
                                      },
                                      model: {
                                        value: _vm.data.regDtStr,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regDtStr", $$v)
                                        },
                                        expression: "data.regDtStr",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "개정자",
                                        name: "regUserName",
                                      },
                                      model: {
                                        value: _vm.data.regUserName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regUserName", $$v)
                                        },
                                        expression: "data.regUserName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        afterIcon: _vm.editable
                                          ? [
                                              {
                                                name: "search",
                                                click: true,
                                                callbackName: "searchMoc",
                                                color: "teal",
                                              },
                                              {
                                                name: "close",
                                                click: true,
                                                callbackName: "removeMoc",
                                                color: "red",
                                              },
                                            ]
                                          : null,
                                        editable: _vm.editable,
                                        disabled: true,
                                        label: "MOC번호",
                                        name: "sopMocId",
                                      },
                                      on: {
                                        searchMoc: _vm.searchMoc,
                                        removeMoc: _vm.removeMoc,
                                      },
                                      model: {
                                        value: _vm.data.sopMocId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "sopMocId", $$v)
                                        },
                                        expression: "data.sopMocId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "개정사유",
                                        name: "revisionContent",
                                      },
                                      model: {
                                        value: _vm.data.revisionContent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "revisionContent",
                                            $$v
                                          )
                                        },
                                        expression: "data.revisionContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                              staticStyle: { "margin-top": "-18px !important" },
                              attrs: { id: "revdiv" },
                            },
                            [
                              _c("c-table", {
                                ref: "revision",
                                attrs: {
                                  title: "개정이력",
                                  tableId: "revision",
                                  topBorderClass: "topcolor-orange",
                                  columns: _vm.gridrev.columns,
                                  data: _vm.gridrev.data,
                                  gridHeight: "150px",
                                  columnSetting: false,
                                  expandAll: false,
                                  isFullScreen: false,
                                  usePaging: false,
                                  hideBottom: true,
                                  filtering: false,
                                },
                                on: { rowClick: _vm.rowClickRev },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }